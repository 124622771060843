import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CVContent from '../components/cv-content'

const CVTemplate = ({ data, location }) => {
  const { cv } = data

  return (
    <Layout
      location={location}
      title="CV"
      footer={false}
    >
      <SEO
        title="Curriculum Vitae"
        description="My experience, education and skills."
      />
      <div className="max-w-3xl mx-auto px-4 py-12">
        <a
          href="/cv.pdf"
          download={`Dave Calnan CV 30.05.2019.pdf`}
          className="text-blue-500"
        >
          Download as PDF &rarr;
        </a>
        <CVContent content={cv.htmlAst} />
      </div>
    </Layout>
  )
}

export default CVTemplate

export const pageQuery = graphql`
  query {
    cv: markdownRemark(fileAbsolutePath: {regex: "/cv/"}) {
      htmlAst
    }
  }
`
